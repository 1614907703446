export const queryParamsWhitelist = {
	names: new Set([
		'currency',
		'metaSiteId',
		'isqa',
		'experiments',
		'experimentsoff',
		'suppressbi',
		'sampleratio',
		'hot',
		'viewerPlatformAppSources',
		'wixCodeForceKibanaReport',
		'controllersUrlOverride',
		'debug',
		'petri_ovr',
		'iswixsite',
		'showMobileView',
		'lang',
		'ssrDebug',
		'wixAdsOverlay',
		'ssrIndicator',
		'siteRevision',
		'forceThunderbolt',
		'editor-elements-override',
		'widgetsUrlOverride',
		'viewerPlatformOverrides',
		'overridePlatformBaseUrls',
		'thunderboltTag',
		'tpasUrlOverride',
		'tpasWorkerUrlOverride',
	]),
	matchers: [/[^\s]+-override$/],
}
