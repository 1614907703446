import { named, withDependencies } from '@wix/thunderbolt-ioc'
import { FeatureStateSymbol, IAppWillLoadPageHandler, ISamePageUrlChangeListener } from '@wix/thunderbolt-symbols'
import { IFeatureState } from 'thunderbolt-feature-state'
import { BsiManagerSymbol, name } from './symbols'
import { IBsiManager, IPageNumber, PageNumberState } from './types'

const pageNumberHandlerFactory = (
	featureState: IFeatureState<PageNumberState>,
	bsiManager: IBsiManager
): IAppWillLoadPageHandler & IPageNumber & ISamePageUrlChangeListener => {
	let firstPageRender = true
	const updatePageNumberAndReport = (defaultPageNumber: number) => {
		const currentPageNumber = featureState.get()?.pageNumber || defaultPageNumber
		const nextPageNumber = currentPageNumber + 1
		featureState.update(() => ({
			pageNumber: nextPageNumber,
		}))
		bsiManager.reportActivity()
	}
	return {
		appWillLoadPage: () => {
			updatePageNumberAndReport(0)
			firstPageRender = true
		},
		getPageNumber: () => featureState.get()?.pageNumber || 1,
		// same page url change
		onUrlChange: () => {
			!firstPageRender && updatePageNumberAndReport(1)
			firstPageRender = false
		},
	}
}

export const PageNumberHandler = withDependencies(
	[named(FeatureStateSymbol, name), BsiManagerSymbol],
	pageNumberHandlerFactory
)
